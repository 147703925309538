import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '@portal-output/core/services/core.service';
import { Observable, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseResolverService implements Resolve<number> {
  constructor(private coreService: CoreService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
    return this.coreService.getLicenseId$().pipe(
      first()
    );
  }
}
