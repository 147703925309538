import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MadCloudResult } from '@common/http/models/mad-cloud-result.model';
import { ApiService } from '@portal-core/auth/services/api.service';
import { AuthApiService } from '@portal-core/auth/services/auth-api.service';
import { ErrorService } from '@portal-core/errors/services/error.service';
import { CoreDataService } from '@portal-output/core/services/core-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthSiteApiService extends AuthApiService {
  constructor(http: HttpClient, errorService: ErrorService, private coreDataService: CoreDataService, apiService: ApiService) {
    super(http, errorService, apiService);
  }

  requestPasswordReset$(email: string): Observable<MadCloudResult> {
    const siteKey = this.coreDataService.getSiteKey();
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/${siteKey}/ForgotPassword`, null, {
      params: { email }
    });
  }

  requestSiteAccess$(): Observable<MadCloudResult> {
    const siteKey = this.coreDataService.getSiteKey();
    return this.http.post<MadCloudResult>(`${this.apiService.centralApiBaseUri}/api/users/${siteKey}/RequestSiteAccess`, null);
  }
}
