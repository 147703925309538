import { Injectable } from '@angular/core';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { LicenseUser } from '@portal-core/license-users/models/license-user.model';
import { LicenseUsersService } from '@portal-core/license-users/services/license-users.service';
import { LicenseActivationType } from '@portal-core/licenses/enums/license-activation-type.enum';
import { License } from '@portal-core/licenses/models/license.model';
import { LicensesService } from '@portal-core/licenses/services/licenses.service';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { CoreDataService } from '@portal-output/core/services/core-data.service';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(
    private coreDataService: CoreDataService,
    private licensesService: LicensesService,
    private licenseUsersService: LicenseUsersService,
    private authService: AuthService,
    private sitesService: SitesService,
  ) { }

  // User
  getUserId(): string {
    return this.authService.getUserId();
  }

  // LicenseUser
  getLicenseUser(): LicenseUser {
    return this.licenseUsersService.getLicenseUserByUserId(this.getUserId(), this.getLicenseId());
  }

  getLicenseUser$(): Observable<LicenseUser> {
    return this.licenseUsersService.getLicenseUserByUserId$(this.getUserId(), this.getLicenseId());
  }

  // License
  getLicenseId(): number {
    return this.coreDataService.getLicenseId();
  }

  getLicenseId$(): Observable<number> {
    return this.coreDataService.getLicenseId$();
  }

  getLicense$(): Observable<License> {
    return this.licensesService.getItemById$(this.getLicenseId());
  }

  getLicense(): License {
    return this.licensesService.getItemById(this.getLicenseId());
  }

  // Active License
  getActiveLicense(): License {
    const license = this.getLicense();
    return license && license.LicenseActivationStatus === LicenseActivationType.Active ? license : null;
  }

  getActiveLicense$(): Observable<License> {
    return this.getLicense$().pipe(
      map(license => license && license.LicenseActivationStatus === LicenseActivationType.Active ? license : null)
    );
  }

  // Site
  getSiteKey(): string {
    return this.coreDataService.getSiteKey();
  }

  getSiteKey$(): Observable<string> {
    return this.coreDataService.getSiteKey$();
  }

  setSiteKey$(siteKey: string): Observable<any> {
    const licenseId$: Observable<number> = this.sitesService.isValidSiteKey(siteKey) ? this.sitesService.getLicenseId$(siteKey) : of(null);

    return licenseId$.pipe(
      catchError(() => of(null)),
      switchMap(licenseId => this.coreDataService.setSite$(siteKey, licenseId)),
      switchMap(() => this.sitesService.injectSiteStyles$(siteKey))
    );
  }

  // Return Url
  getReturnUrl(): string {
    return this.coreDataService.getReturnUrl();
  }

  setReturnUrl$(returnUrl: string): Observable<any> {
    return this.coreDataService.setReturnUrl$(returnUrl);
  }
}
