import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthStatusMessage } from '@portal-core/auth/enums/auth-status-message.enum';
import { OnAuthError } from '@portal-core/auth/interfaces/auth-error.interface';
import { OnAuthRoute } from '@portal-core/auth/interfaces/auth-route.interface';
import { AuthRedirect } from '@portal-core/auth/types/auth-redirect.type';
import { AlertType } from '@portal-core/general/enums/alert-type.enum';
import { EmptySiteKey } from '@portal-core/sites/constants/empty-site-key.constant';
import { SitesService } from '@portal-core/sites/services/sites.service';
import { CoreService } from '@portal-output/core/services/core.service';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthEventHandlerService implements OnAuthError, OnAuthRoute {
  constructor(private router: Router, private coreService: CoreService, private sitesService: SitesService) { }

  onAuthError(type: AuthStatusMessage, error: any, request: HttpRequest<any>) {
    const siteKey = this.coreService.getSiteKey() || EmptySiteKey;

    this.router.navigate(['/', siteKey, 'alert'], { queryParams: { type } });
  }

  onAuthRoute$(authRoute: AuthRoute, extras?: NavigationExtras): Observable<AuthRedirect> {
    const siteKey = this.coreService.getSiteKey() || EmptySiteKey;

    switch (authRoute) {
      case AuthRoute.ExpiredPassword:
        return of({ commands: ['/', siteKey, 'expired-password'], extras });
      case AuthRoute.ForgotPassword:
        return of({ commands: ['/', siteKey, 'forgot-password'], extras });
      case AuthRoute.Home:
        return this.getAuthHomeRoute$();
      case AuthRoute.Login:
        return of({ commands: ['/', siteKey, 'login'], extras });
      case AuthRoute.Logout:
        return of({ commands: ['/', siteKey, 'logout'], extras });
      case AuthRoute.AuthLoginGuardError:
        return of({
          commands: ['/', siteKey, 'alert'], extras: {
            queryParams: { type: AlertType.AuthLoginError },
            ...extras
          }
        });
    }
  }

  getAuthHomeRoute$(): Observable<AuthRedirect> {
    // Try the return url
    const returnUrl = this.coreService.getReturnUrl();
    if (returnUrl) {
      return of(returnUrl);
    }

    // Try the site key
    const siteKey = this.coreService.getSiteKey() || EmptySiteKey;
    if (this.sitesService.isValidSiteKey(siteKey)) {
      return this.sitesService.getSiteIndexUrl$(siteKey).pipe(
        map(siteIndexUrl => {
          if (siteIndexUrl) {
            return siteIndexUrl;
          } else { // Else if a url was not found then navigate to the alert page
            return {
              commands: ['/', siteKey, 'alert'],
              extras: { queryParams: { type: AlertType.SiteUrlNotFound } }
            };
          }
        })
      );
    }

    // Try the user's sites
    const licenseId = this.coreService.getLicenseId();
    if (licenseId) {
      return this.sitesService.getCurrentUserSites$(licenseId, true).pipe(
        map(userSites => {
          if (Array.isArray(userSites) && userSites.length === 1) {
            return userSites[0].SiteUrl;
          } else { // Else we can't default to one user site so navigate to the site list page
            return { commands: ['/', siteKey, 'sites'] };
          }
        })
      );
    }

    // The route could not be determined so navigate to the alert page
    return of({
      commands: ['/', siteKey, 'alert'],
      extras: { queryParams: { type: AlertType.SiteUrlNotFound } }
    });
  }
}
