import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

export class CoreStateModel {
  licenseId: number;
  returnUrl: string;
  siteKey: string;
}

// Actions
export class SetReturnUrl {
  static readonly type: string = '[Core] SetReturnUrl';
  constructor(public payload: { returnUrl: string }) { }
}

export class SetSite {
  static readonly type: string = '[Core] SetSite';
  constructor(public payload: { siteKey: string, licenseId: number }) { }
}

// CoreState
@State<CoreStateModel>({
  name: 'coreState',
  defaults: {
    licenseId: null,
    returnUrl: null,
    siteKey: null
  }
})
@Injectable()
export class CoreState {
  @Selector()
  static licenseId(state: CoreStateModel): number { return state.licenseId; }

  @Selector()
  static returnUrl(state: CoreStateModel): string { return state.returnUrl; }

  @Selector()
  static siteKey(state: CoreStateModel): string { return state.siteKey; }

  @Action(SetReturnUrl)
  setReturnUrl(ctx: StateContext<CoreStateModel>, action: SetReturnUrl) {
    ctx.patchState({
      returnUrl: action.payload.returnUrl
    });
  }

  @Action(SetSite)
  SetSite(ctx: StateContext<CoreStateModel>, action: SetSite) {
    ctx.patchState({
      siteKey: action.payload.siteKey,
      licenseId: action.payload.licenseId,
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoreDataService {
  constructor(private store: Store) { }

  // License Id
  getLicenseId(): number {
    return this.store.selectSnapshot(CoreState.licenseId);
  }

  getLicenseId$(): Observable<number> {
    return this.store.select(CoreState.licenseId);
  }

  // Site
  getSiteKey(): string {
    return this.store.selectSnapshot(CoreState.siteKey);
  }

  getSiteKey$(): Observable<string> {
    return this.store.select(CoreState.siteKey);
  }

  setSite$(siteKey: string, licenseId: number): Observable<any> {
    return this.store.dispatch(new SetSite({ siteKey, licenseId }));
  }

  // Return Url
  getReturnUrl(): string {
    return this.store.selectSnapshot(CoreState.returnUrl);
  }

  getReturnUrl$(): Observable<string> {
    return this.store.select(CoreState.returnUrl);
  }

  setReturnUrl$(returnUrl: string): Observable<any> {
    return this.store.dispatch(new SetReturnUrl({ returnUrl }));
  }
}
