import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthRoute } from '@portal-core/auth/enums/auth-route.enum';
import { AuthRouteService } from '@portal-core/auth/services/auth-route.service';
import { AuthService } from '@portal-core/auth/services/auth.service';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubdomainGuardService implements CanActivate {
  constructor(private authService: AuthService, private authRouteService: AuthRouteService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Route guards can be called multiple times as navigation occurs. Check if the subdomain has already been validated before doing the work to validate it.
    if (this.authService.hasValidatedSubdomain()) {
      return of(this.authService.isValidSubdomain());
    }

    return this.authService.getSubdomainLicense$().pipe(
      map(license => {
        if (license) {
          return true;
        } else {
          this.authRouteService.navigateToAuthRoute$(AuthRoute.SubdomainGuardError).subscribe();
          return false;
        }
      }),
      catchError(() => {
        this.authRouteService.navigateToAuthRoute$(AuthRoute.SubdomainGuardError).subscribe();
        return of(false);
      })
    );
  }
}
