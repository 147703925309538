import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY } from '@angular/material/tooltip';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthCookieEffect } from '@portal-core/auth/effects/auth-cookie.effect';
import { AuthLogoutRedirectEffect } from '@portal-core/auth/effects/auth-logout-redirect.effect';
import { AuthResettablesEffect } from '@portal-core/auth/effects/auth-resettables.effect';
import { AuthInterceptorService } from '@portal-core/auth/interceptors/auth-interceptor.service';
import { AuthApiService } from '@portal-core/auth/services/auth-api.service';
import { AuthServicesModule } from '@portal-core/auth/services/auth-services.module';
import { BuildsServicesModule } from '@portal-core/builds/services/builds-services.module';
import { CurrentServicesModule } from '@portal-core/current/services/current-services.module';
import { GeneralServicesModule } from '@portal-core/general/services/general-services.module';
import { I18nServicesModule } from '@portal-core/i18n/services/i18n-services.module';
import { LicenseUsersServicesModule } from '@portal-core/license-users/services/license-users-services.module';
import { LicensesServicesModule } from '@portal-core/licenses/services/licenses-services.module';
import { MessagesServicesModule } from '@portal-core/messages/services/messages-services.module';
import { NotificationsServicesModule } from '@portal-core/notifications/services/notifications-services.module';
import { PermissionsServicesModule } from '@portal-core/permissions/services/permissions-services.module';
import { ProfilesServicesModule } from '@portal-core/profiles/services/profiles-services.module';
import { ProjectChecklistsServicesModule } from '@portal-core/project-checklists/services/project-checklists-services.module';
import { ProjectTargetsServicesModule } from '@portal-core/project-targets/services/project-targets-services.module';
import { ProjectHydratorEffect } from '@portal-core/projects/effects/project-hydrator.effect';
import { ProjectsServicesModule } from '@portal-core/projects/services/projects-services.module';
import { ReviewsServicesModule } from '@portal-core/reviews/services/reviews-services.module';
import { SiteListByBuildEffect } from '@portal-core/sites/effects/site-list-by-build.effect';
import { SitesServicesModule } from '@portal-core/sites/services/sites-services.module';
import { SlackWebhooksServicesModule } from '@portal-core/slack/services/slack-webhooks-services.module';
import { TasksServicesModule } from '@portal-core/tasks/services/tasks-services.module';
import { TeamsServicesModule } from '@portal-core/teams/services/teams-services.module';
import { addIdAddTypeToPageFilterGroup } from '@portal-core/ui/page-filters/migrations/add.id.add.type.to.page-filter-group.migration';
import { UsersServicesModule } from '@portal-core/users/services/users-services.module';
import { appInitializerNoop } from '@portal-core/util/app-initializer-noop';
import { WINDOW_PROVIDERS } from '@portal-core/util/window.provider';
import { AuthEventHandlerService } from '@portal-output/core/auth/auth-event-handler.service';
import { AuthSiteApiService } from '@portal-output/core/auth/auth-site-api.service';
import { CurrentConfiguratorService } from '@portal-output/core/current/current-configurator.service';
import { CoreState } from '@portal-output/core/services/core-data.service';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [],
  imports: [
    // The NGXS root module must come before the NGXS feature modules (which are defined in our feature modules below)
    NgxsModule.forRoot([
      CoreState
    ]),
    AuthServicesModule.forRoot({
      effects: [AuthCookieEffect, AuthResettablesEffect, AuthLogoutRedirectEffect],
      onAuthError: [AuthEventHandlerService],
      onAuthRoute: AuthEventHandlerService
    }),
    BuildsServicesModule,
    CommonModule,
    CurrentServicesModule.forRoot({
      getLicenseUser: CurrentConfiguratorService,
      getLicenseUser$: CurrentConfiguratorService,
      getLicense: CurrentConfiguratorService,
      getLicense$: CurrentConfiguratorService,
      getActiveLicense: CurrentConfiguratorService,
      getActiveLicense$: CurrentConfiguratorService,
      isSysAdmin: CurrentConfiguratorService
    }),
    HttpClientModule,
    I18nServicesModule,
    GeneralServicesModule,
    LicenseUsersServicesModule,
    LicensesServicesModule,
    MessagesServicesModule,
    // NgxsStoragePluginModule should be the first NGXS plugin in the imports list
    NgxsStoragePluginModule.forRoot({
      key: [
        'coreState.licenseId'
      ],
      // Run any migrations or clean up
      afterDeserialize: (state, key) => {
        return addIdAddTypeToPageFilterGroup(state, key);
      }
    }),
    // NgxsReduxDevtoolsPluginModule must be the last NGXS plugin in the imports list
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NotificationsServicesModule,
    PermissionsServicesModule,
    ProfilesServicesModule,
    ProjectsServicesModule,
    ProjectChecklistsServicesModule,
    ProjectTargetsServicesModule,
    ReviewsServicesModule,
    SitesServicesModule,
    SlackWebhooksServicesModule,
    TasksServicesModule,
    TeamsServicesModule,
    UsersServicesModule
  ],
  providers: [
    CookieService,
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useFactory: function () {
        return {
          ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
          disableTooltipInteractivity: true
        };
      }
    },
    { provide: AuthApiService, useClass: AuthSiteApiService },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerNoop,
      deps: [ProjectHydratorEffect, SiteListByBuildEffect],
      multi: true
    }
  ]
})
export class CoreModule { }
